import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Styling
import "@scss/style.scss";

// Redux
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { appReducer } from "./app-reducer";
import { timelineReducer } from "./general-components/content-base/paragraphs/timeline/reducer-timeline";
//import { calendarMonthReducer } from "./general-components/content-base/paragraphs/calendar-month/reducer-calendar-month";
import { cloudViewReducer } from "./general-components/content-base/paragraphs/content-cloud/cloud-view-reducer";
import i18nReducer from "./i18n/i18n-slice";

// Login
import {
  authenticationLoginAction,
  authenticationReducer,
} from "./intern/redux-authentication";
import { persistReducer, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { setContext } from "@apollo/client/link/context";

// GraphQL
import { restHost, graphQlEndpoint } from "./config";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getCurrentLanguage } from "./lib/lib";
import routes from "./routes";
import inMemoryCache from "./cache";

const rootReducer = combineReducers({
    appStore: appReducer,
    timeLine: timelineReducer,
    //kalenderMonth: kalenderMonthReducer,
    contentCloud: cloudViewReducer,
    i18n: i18nReducer,
    auth: authenticationReducer,
  }),
  persistedReducer = persistReducer(
    {
      key: "root",
      storage,
      whitelist: ["auth"],
      stateReconciler: autoMergeLevel2,
    },
    rootReducer
  ),
  store = createStore(persistedReducer, window.__PRELOADED_STATE__),
  authLink = setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists.
    const storeState = store.getState(),
      token = storeState.auth.userLoginData.access_token;

    if (
      storeState.auth.userLoginData.access_token &&
      storeState.auth.userLoginData.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      console.log("RESET LOGIN CLIENT AUTHLINK");
      store.dispatch(authenticationLoginAction({}));
    }

    // Return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        // Get from store.
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }),
  errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  client = new ApolloClient({
    link: authLink.concat(
      from([
        errorLink,
        createHttpLink({
          uri: () => {
            const currentLanguage = getCurrentLanguage(
              window.location.pathname
            );

            return `${restHost}${
              ["en"].includes(currentLanguage) ? "/" + currentLanguage : ""
            }${graphQlEndpoint}`;
          },
          credentials: "same-origin",
        }),
      ])
    ),
    cache: inMemoryCache,
  }),
  persistor = persistStore(store);

// Allow the passed states to be garbage-collected
delete window.__PRELOADED_STATE__;

let router = createBrowserRouter(routes);

// @todo: Produces Warning on dev build.
const container = document.getElementById("app");
if (process.env.NODE_ENV === "development") {
  let root = null;
  if (!root) {
    root = createRoot(container);
  }

  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} fallbackElement={null} />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
} else {
  hydrateRoot(
    container,
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} fallbackElement={null} />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

if (module["hot"]) {
  module["hot"].accept();
}
