import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import SubmissionsQuery from "./submissions.graphql";
import toast from "@general-components/ui/toast";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const CardSubmissions = ({ view }) => {
  const { data, loading, error } = useQuery(SubmissionsQuery, {
    variables: {
      view,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    toast({
      message: "Fehler beim Laden der Daten [submission_query].",
      type: "error",
    });
    return <div>Error: {error.message}</div>;
  }

  const nodes = data?.entityById?.executable?.execute?.rows;

  return (
    <div className="card">
      <div className="card-header">
        <h3>
          <FormattedMessage id={`submissions.header.${view}`} />
        </h3>
      </div>
      <div className="card-body">
        {nodes?.length ? (
          <ul className="list-group">
            {nodes.map((node) => (
              <li key={node.id} className="card-submission list-group-item">
                <div className="card-submission__title">
                  {node.title} (ID: {node.fieldWebformSubmissionId?.id})
                </div>
                {node.fieldWebformSubmissionId?.id && (
                  <Link
                    to={`/intern/submit-event/${node.fieldWebformSubmissionId.id}`}
                  >
                    bearbeiten
                  </Link>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div>Keine Einträge vorhanden.</div>
        )}
      </div>
      {/* @todo: Do not use static URLs for drupal generated content */}
      <div className="card-footer">
        <Link to="/intern/submit-event">Termin Einreichen</Link>
      </div>
    </div>
  );
};

CardSubmissions.propTypes = {
  view: PropTypes.oneOf(["event_submissions"]).isRequired,
};

export default CardSubmissions;
