import { useQuery } from "@apollo/client";
import ParagraphForm from "@paragraphs/webform/paragraph-form";
import React from "react";
import { useParams } from "react-router-dom";
import webformSubmissionQuery from "./webform-submission.graphql";

const PageSubmitEvent = () => {
  const { eventSubmissionId } = useParams();

  const { loading, error, data } = useQuery(webformSubmissionQuery, {
    variables: {
      sid: eventSubmissionId,
    },
    skip: !eventSubmissionId,
  });

  if (loading) {
    return null;
  }

  if (eventSubmissionId && !data?.entityById?.encodedData) {
    throw new Response("Not Found", { status: 404 });
  }

  const submissionData = data?.entityById?.encodedData
    ? JSON.parse(data?.entityById.encodedData)
    : null;

  return (
    <article>
      <ParagraphForm
        content={{
          fieldFormRawField: { first: { targetId: "event_submission" } },
        }}
        defaultInitialValues={submissionData}
        submissionId={eventSubmissionId || null}
      />
    </article>
  );
};

export default PageSubmitEvent;
