import React from "react";

import AppLayout from "./app";
import Event from "@nodes/event/event";
import Landingpage from "@nodes/landingpage/landingpage";
import News from "@nodes/news/news";
import Person from "@nodes/person/person";
import WebformPage from "./intern/webform-page/webform-page";
import PageDashboard from "./intern/pages/page-dashboard";
import PageLogin from "./intern/pages/page-login";
import InternLayout from "./intern/pages/layout";
import PageForgotPassword from "./intern/pages/page-forgot-password";
import PageResetPassword from "./intern/pages/page-reset-password";
import PageSubmitEvent from "./intern/pages/page-submit-event";
import RouteError from "./route-error";
import ErrorBoundary from "./error-boundary";

const routes = [
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <RouteError />,
    children: [
      {
        path: ":language(en|de)?/:alias",
        element: <Landingpage />,
      },
      {
        path: ":language(en|de)?/news/:alias",
        element: <News />,
      },
      {
        path: ":language(en|de)?/veranstaltung/:alias",
        element: <Event />,
      },
      {
        path: ":language(en|de)?/person/:alias",
        element: <Person />,
      },
      {
        path: "intern",
        element: <InternLayout />,
        children: [
          {
            path: "login",
            element: <PageLogin />,
          },
          {
            path: "forgot-password",
            element: <PageForgotPassword />,
          },
          {
            path: "reset-password",
            element: <PageResetPassword />,
          },
          {
            path: "webform/:webformId/:submissionId?",
            element: <WebformPage />,
          },
          {
            path: "dashboard",
            element: <PageDashboard />,
          },
          {
            path: "submit-event/:eventSubmissionId?",
            element: <PageSubmitEvent />,
            errorElement: <RouteError />
          },
          {
            path: ":alias",
            element: <Landingpage />,
          },
        ],
      },
    ],
  },
];

export default routes;
