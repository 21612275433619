import React from "react";
import PropTypes from "prop-types";

/**
 * @typedef {"primary" | "secondary" | "tertiary"} ButtonType
 */

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {ButtonType} [props.type="primary"]
 */
const Button = ({ children, type = "primary", ...props }) => {
  return (
    <button className={`btn btn-${type}`} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  /**
   * The type of the button.
   */
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
};

export default Button;
