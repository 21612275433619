import Error404 from "@system/error-404";
import React from "react";
import { useRouteError } from "react-router-dom";

const RouteError = () => {
  const error = useRouteError();

  if (error?.status === 404) {
    return <Error404 />;
  }

  console.error("ROUTE ERROR", error)
};

export default RouteError;
