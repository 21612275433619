import React from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import useAuthentication from "../use-authentication";

const InternLayout = () => {
  const { auth } = useAuthentication();
  const location = useLocation();

  console.log(location);

  const loggedIn = auth.userLoginData.access_token !== undefined;

  /**
   * Layout for system authentication pages (login, forgot password, reset password).
   */
  if (!loggedIn) {
    return <Outlet />;
  }

  /**
   * Layout for internal pages.
   */
  return (
    <article>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <p>
              Interne Seite
              {!location.pathname.includes("dashboard") && (
                <>
                  {" | "}
                  <Link to="/intern/dashboard">Zurück zum Dashboard</Link>
                </>
              )}
            </p>
          </div>
        </div>
      </div>
      <Outlet />
    </article>
  );
};

InternLayout.propTypes = {};

export default InternLayout;
